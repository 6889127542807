<template>
  <section class="file-manager w-full h-full">
    <tabs
      :tabs="tabs"
      :selected="selected"
      @on-tab-change="onTabChange($event)"
      v-if="showTabs"
      :loading="isLoading"
    />
    <template v-if="isLoading">
      <div class="text-center p-8">
        <loader />
      </div>
    </template>
    <template v-else>
      <div v-if="directories?.length > 0 && profileTab() !== 3"
        class="mt-4 flex items-center justify-between"
        v-allow:auth="'case.internal_folder'"
      >
        <template>
          <div
            class="files flex items-center gap-2 pb-2 overflow-x-auto scroll-bar"
          >
            <Folders
              v-for="directory in directories"
              :key="directory.id"
              :directory="directory"
              @on-click="onFolderClick($event, true)"
              :selectedFolder="topFolderSelected"
            />
          </div>
        </template>

        <!-- <template v-if="!readOnly && profileTab() !== 3">
          <del-button
            v-if="checkPermission('case.actions_external')"
            class="ml-4 py-2"
            text="Generate Report"
            type="primary"
            :loader="report.loading()"
            @click="report.generateReport()"
          />
        </template> -->
      </div>
      <template v-if="topFolderSelected">
        <Explorer
          :readOnly="readOnly"
          :enableSelection="enableSelection"
          :directories="currentContent"
          :breadcrumbs="getBreadcrumbs"
          @on-directory-click="onFolderClick($event)"
          @on-breadcrumb-click="onBreadcrumbClick($event)"
          @on-add="onAdd($event)"
          @on-delete-directory="onDeleteDirectory($event)"
          :isClient="isClient"
          @on-selected-rows-change="$emit('on-selected-rows-change', $event)"
        />
      </template>
    </template>
    <!-- modals  -->
    <AddFile ref="file-add" @on-add-file="onAddfile($event)" />
    <AddFolder ref="folder-add" @on-add-folder="onAddFolder($event)" />
  </section>
</template>

<script>
import axios from "@/axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import Tabs from "@shared/Tabs";
import Folders from "./components/Folders.vue";
import Explorer from "./components/Explorer.vue";
import Loader from "@shared/loader";
const AddFile = () => import("./components/AddFile.vue");
const AddFolder = () => import("./components/AddFolder.vue");
import { EventBus } from "@/main.js";
import { checkPermission } from "@shared/utils/functions";

export default {
  name: "file-manager",

  components: {
    Tabs,
    Folders,
    Explorer,
    AddFile,
    AddFolder,
    Loader,
  },

  data() {
    return {
      selectedFolder: null,
      breadcrumbs: [],
      topFolderSelected: null,
      folders: null,
    };
  },

  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: [String, Number],
      default: "",
    },
    profileTab: {
      type: [Function],
      default: () => {},
    },
    report: {
      type: Object,
      default: () => {},
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    directories: {
      type: Array,
      default: () => [],
    },
    directory_type: {
      type: String,
      default: "input",
    },
    enableSelection: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
    portal: {
      type: String,
      default: 'tenant'
    }
  },

  methods: {
    checkPermission,
    onTabChange(tab) {
      if (tab.key === this.selected) return;
      this.$emit("on-tab-change", tab);
    },
    onAdd(type) {
      this.$refs[`${type}-add`].showModal();
    },
    onFolderClick(folder, isTop = false) {
      if (folder.type === "directory") {
        this.selectedFolder = folder.id;
        if (isTop) {
          this.topFolderSelected = folder.id;
          this.breadcrumbs = [folder];
        } else {
          this.breadcrumbs.push(folder);
        }
      } else {
        // window.open(`/${folder.id}`)
        this.$emit("on-file-open", folder);
      }
    },
    onBreadcrumbClick(breadcrumb) {
      if (breadcrumb.id === this.selectedFolder) {
        return;
      }
      const breadcrumbIndex = this.breadcrumbs.findIndex(
        (bread) => bread.id === breadcrumb.id
      );
      if (breadcrumbIndex != -1)
        this.breadcrumbs = this.breadcrumbs.slice(0, breadcrumbIndex + 1);
    },
    resetState() {
      this.selectedFolder = null;
      this.breadcrumbs = [];
      this.topFolderSelected = null;
    },
    onAddFolder({ name, description }) {
      this.$emit("on-add-folder", {
        name,
        description,
        directory_type: this.directory_type,
        content: this.currentContent,
      });
    },
    onAddfile(details) {
      this.$emit("on-add-files", {
        ...details,
        content: this.currentContent,
        directory_type: this.directory_type,
      });
    },
    onDeleteDirectory(data) {
      this.$emit("on-delete-directory", {
        ...data,
        directory_type: this.directory_type,
      });
    },
    async getFolders() {
      const payload = {
        case_id: this.$route?.query?.caseid,
        // check_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        directory_type: "output",
      };

      try {
        const { data } = await axios.post(
          `/case-file-manager/client/get-file-data`,
          payload
        );
        this.folders = data?.data ?? [];
        if (this.folders?.length) {
          let match = this.folders.find(
            (el) => el.name === this.getCaseData.client_name
          );
          if (match) {
            this.onFolderClick(match, true);
          }
          if (match) {
            let folder = match.content.find(
              (el) => el.name.toLowerCase() == "case report"
            );
            if (folder) this.onFolderClick(folder);
          }
        }
      } catch (error) {
        console.log(error, "[+] error while fetching files");
      }
    },
    async fetchFiles() {
      const payload = {
        case_entity_id: this.getEntityId,
        case_id: this.$route?.query?.caseid,
        directory_type: "output",
      };
      try {
        const { data } = await axios.post(
          `/case-file-manager/${this.portal}/entity/get-file-data`,
          payload
        );
        return data?.data ?? [];
      } catch (error) {
        console.log(error, "[+] error while fetching files");
      }
    },
  },
  computed: {
    ...mapGetters(["getCaseData"]),
    getBreadcrumbs() {
      return this.breadcrumbs;
    },
    currentContent() {
      // let contents = cloneDeep(this.directories).find((directory) => directory.id === this.selectedFolder);
      let contents = null;
      this.breadcrumbs?.forEach((breadcrumb) => {
        if (contents) {
          contents = contents?.content?.find(
            (directory) => directory.id === breadcrumb.id
          );
        } else {
          contents = this.directories.find(
            (directory) => directory.id === breadcrumb.id
          );
        }
      });
      return { ...contents, content: contents?.content ?? [] };
    },
    getEntityId() {
      return this.$route.query.entityId;
    },
  },
  async mounted() {
    EventBus.$on('fetchFileDetails', async ()=> {
        const directories = await this.fetchFiles();

        const folder = directories?.[0];
        if (!folder) return;

        this.breadcrumbs = [folder];
        this.onFolderClick(folder, true);
    });

    if (VueCookies.get("next-url")?.includes("#fileManager")) {
      this.getFolders();
    }
    if (this.$route.path?.includes("outputs")) {
      this.getFolders();
    }

    if (this.directory_type === "output" || this.profileTab() === 3) {
      if (!this.directories.length) {
        const directories = await this.fetchFiles();
        const folder = directories[0];
        this.breadcrumbs = [folder];
        this.onFolderClick(folder, true);
      }
    }
  },
};
</script>

<style></style>
