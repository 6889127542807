<template>
    <div class="text-sm font-semibold text-center text-gray-700 border-b border-gray-300">
        <ul class="flex flex-wrap -mb-px">
            <li class="mr-2" v-for="tab in tabs" :key="tab.key" @click="onTabChange(tab)">
                <div class="cursor-pointer inline-block py-2 px-6 border-b-4 border-transparent rounded-t-lg hover:text-primary-focus hover:border-primary" :class="{ 'border-primary text-primary': selected === tab.key, 'border-gray-300 text-gray-600 pointer-events-none': loading }">{{ tab.name }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        tabs: {
            type: Array,
            default: () => [],
            // [{name,key}]
        },
        selected: {
            type: [String, Number],
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onTabChange(tab) {
            if (this.loading) return;
            this.$emit("on-tab-change", tab);
        },
    },
};
</script>

<style lang="scss" scoped></style>
